.intro-section {
    height: 100%;
    background-color: #efdf00;
    user-select: none;
    cursor: default;
    display: flex;
    flex-direction: column;

    .contents {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        padding-right: 150px;
        padding-top: 15px;

        .intro-text {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            font-family: "Red Hat Display", sans-serif;

            .bold {
                display: inline;
                font-weight: 700;
            }
        }
    }

    .footer {
        margin: auto;
        width: 180px;
        flex: 0 0 30px;
        background-color: rgba($color: #000000, $alpha: 0.4);
        border-radius: 8px 8px 0px 0px;
        font-family: "Red Hat Display", sans-serif;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;

        .icon-animation {
            animation-name: carethover;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease;
        }

        @keyframes carethover {
            from {
                transform: translate(0px, -3px);
            }

            to {
                transform: translate(0px, 3px);
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .intro-section {
        .contents {
            padding-right: 0px;
            padding-top: 0px;
            flex-direction: column-reverse;

            .intro-text {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                .about-text {
                    font-size: 22px;
                }

                .skill-text {
                    font-size: 15px;
                }
            }

            .animation-player {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .intro-section {
        .contents {
            padding-right: 0px;
            padding-top: 0px;

            .intro-text {
                .about-text {
                    font-size: 38px;
                }

                .skill-text {
                    font-size: 25px;
                }
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .intro-section {
        .contents {
            padding-right: 0px;
            padding-top: 0px;

            .intro-text {
                .about-text {
                    font-size: 40px;
                }

                .skill-text {
                    font-size: 24px;
                }
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .intro-section {
        .contents {
            padding-right: 150px;
            padding-top: 15px;

            .intro-text {
                .about-text {
                    font-size: 44px;
                }

                .skill-text {
                    font-size: 28px;
                }
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .intro-section {
        .contents {
            padding-right: 160px;
            padding-top: 15px;

            .intro-text {
                .about-text {
                    font-size: 52px;
                }

                .skill-text {
                    font-size: 30px;
                }
            }
        }
    }
}
