html {
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  min-height: -webkit-fill-available;
}

div {
  box-sizing: border-box;
}
